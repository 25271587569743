<template>
  <div id="unitContainer" class="relative">

    <div class="loadingContainer">
      <div v-if="notificationData">

        <!--      {{notificationData}}-->

        <vs-list>
          <vs-list-item class="unit pt-5"
                        :title="notificationData.name"
                        :subtitle="notificationData.coveredMunicipalities"></vs-list-item>

          <vs-list-header icon="person" title="Koordinator enote" color="info"></vs-list-header>
          <vs-list-item :title="notificationData.volunteers.first_name + ' ' + notificationData.volunteers.last_name"
                        :subtitle="notificationData.volunteers.notes">
            <template slot="avatar">
              <vs-avatar :src="$globalFunctions.getUserAvatar(notificationData.volunteers.profile_image)"/>
            </template>
          </vs-list-item>

          <vs-list-header icon="person" title="Skrbnik enote" color="info" v-if="unitManager"></vs-list-header>
          <vs-list-item v-if="unitManager" :title="unitManager.first_name + ' ' + unitManager.last_name">
            <template slot="avatar">
              <vs-avatar :src="$globalFunctions.getUserAvatar(unitManager.profile_image)"/>
            </template>
          </vs-list-item>

          <vs-list-header icon="home" title="Kontaktni podatki enote" color="info"></vs-list-header>
          <vs-list-item :title="notificationData.addresses.quick_search" icon="location_on"></vs-list-item>
          <vs-list-item :title="notificationData.phone_reservations" :subtitle="'Telefonska številka za rezervacije'"
                        icon="call"></vs-list-item>
          <vs-list-item :title="notificationData.phone_volunteer" :subtitle="'Telefonska številka prostovoljskega telefona'"
                        icon="add_ic_call"></vs-list-item>
          <vs-list-item :title="notificationData.mail_recipients ? notificationData.mail_recipients : 'ni dodatnih prejemnikov'"
                        :subtitle="'E-naslovi dodatnih prejemnikov potnih nalogov'" icon="email"></vs-list-item>

          <vs-list-header icon="directions_car" :title="notificationData.cars.length > 1 ? 'Vozila' : 'Vozilo'"
                          color="info"></vs-list-header>


          <vs-list-item v-for="(car, index) in notificationData.cars" :key="'car_' + index" :title="car.name"
                        :subtitle="car.registrationNumber">
            <template slot="avatar">
              <vs-avatar :style="'background-color:' + car.color" :text="car.name"/>
            </template>
          </vs-list-item>

          <vs-collapse class="p-0">
            <vs-collapse-item class="p-0" style="overflow-y: auto;">
              <div slot="header">
                <vs-list-item title="Gesla" icon="vpn_key"></vs-list-item>
              </div>

              <div v-html="notificationData.access_data"></div>

            </vs-collapse-item>
          </vs-collapse>

          <vs-collapse class="p-0">
            <vs-collapse-item class="p-0" style="overflow-y: auto;">
              <div slot="header">
                <vs-list-item title="Demografija" icon="perm_contact_calendar"></vs-list-item>
              </div>

              <div>
                <div v-if="notificationData.demographics">


                  <vs-table :data="notificationData.demographics">
                    <template slot="header">
                      <vs-navbar class="nabarx">
                        <div slot="title">
                          <vs-navbar-title class="py-4">
                            Demografija in statistika
                          </vs-navbar-title>
                        </div>
                      </vs-navbar>
                    </template>
                    <template slot="thead">
                      <vs-th>
                        občina
                      </vs-th>
                      <vs-th>
                        statistična regija
                      </vs-th>
                      <vs-th>
                        proračun
                      </vs-th>
                      <vs-th>
                        površina (km2)
                      </vs-th>
                      <vs-th>
                        št. prebivalcev
                      </vs-th>
                      <vs-th>
                        št. žensk
                      </vs-th>
                      <vs-th>
                        št. moških
                      </vs-th>
                      <vs-th>
                        št. starejših nad 65 let
                      </vs-th>
                      <vs-th>
                        št. žensk nad 65 let
                      </vs-th>
                      <vs-th>
                        št. moških nad 65 let
                      </vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">
                        <vs-td :data="data[index].municipality">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].municipality}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].statsRegion">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].statsRegion}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].budget">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].budget}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].area">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].area}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].inhabitants">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].inhabitants}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].inhabitantsMale">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].inhabitantsMale}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].inhabitantsFemale">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].inhabitantsFemale}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].elderly">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].elderly}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].elderlyMale">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].elderlyMale}}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].elderlyFemale">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{data[index].elderlyFemale}}
                          </vs-col>
                        </vs-td>

                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>

            </vs-collapse-item>
          </vs-collapse>

        </vs-list>


        <vs-row
          vs-align="center"
          vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-2">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button color="primary" icon="edit">Uredi</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button color="danger" icon="delete">Izbriši</vs-button>
          </vs-col>
        </vs-row>


      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";

  export default {
    name: 'reservations-calendar',

    components: {
      DirectionsRenderer
    },
    props: {
      unitId: Number
    },
    data() {
      return {
        notificationData: null,
        allVolunteers: [],
        unitManager: null
      }
    },

    computed: {},

    mounted() {
      const _this = this;


      _this.getUnit();

    },

    watch: {
      unitId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");
          this.notificationData = null;


          if (val) {
            this.getUnit();
          }
        }
      }
    },

    methods: {
      getUnit() {
        const _this = this;


        this.$vs.loading({
          container: '#unitContainer .loadingContainer',
        })


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'units/' + _this.unitId)
          .then((res) => {

            _this.notificationData = res.data.data;
            _this.notificationData.demographics = JSON.parse(res.data.data.demographics);
            console.log("TOLE: ", res.data.data);

            _this.getAllVolunteers();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close('#unitContainer .loadingContainer > .con-vs-loading');
            return false
          });
      },

      getAllVolunteers() {
        const _this = this;

        Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/')
          .then((res) => {

            _this.allVolunteers = res.data.data;
            _this.unitManager = _.find(_this.allVolunteers, {id: _this.notificationData.managed_by_id});
            console.log(_this.unitManager);
            _this.$vs.loading.close('#unitContainer .loadingContainer > .con-vs-loading');
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close('#unitContainer .loadingContainer > .con-vs-loading');
            return false
          });
      }

    }
  }
</script>


<template>
  <div id="unitsContainer">
    <!--    {{notificationsData}}-->
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Obvestila za uporabnike</li>
    </vs-breadcrumb>


    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novega uporabnika" @click.stop="addNotification()"></vs-button>

    <div v-if="notificationsTemplateData && notificationsData">

      <vs-tabs>
        <vs-tab label="Predloge obvestil" icon="check_circle" color="success" @click="">
          <vs-table :data="notificationsTemplateData" @selected="showNotificationDetails">
            <template slot="header">
              <vs-navbar class="nabarx">
                <div slot="title">
                  <vs-navbar-title class="py-4">
                    Predloge obvestil
                  </vs-navbar-title>
                </div>
              </vs-navbar>
            </template>
            <template slot="thead">
              <vs-th>
                Datum
              </vs-th>
              <vs-th>
                Naslov
              </vs-th>
              <vs-th>
                Dogodek
              </vs-th>
<!--              <vs-th>-->
<!--                Prejeto-->
<!--              </vs-th>-->
<!--              <vs-th>-->
<!--                Prebrano-->
<!--              </vs-th>-->
              <vs-th>
                Akcije
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">
                <vs-td :data="data[index].date">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ moment(data[index].date).format('DD. MM. YYYY') }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].title">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].title }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].eventDetails">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].eventDetails ? JSON.parse(data[index].eventDetails)[0].eventTitle : '/' }}
                  </vs-col>
                </vs-td>

<!--                <vs-td :data="data[index].received">-->
<!--                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                    {{ data[index].received }}-->
<!--                  </vs-col>-->
<!--                </vs-td>-->

<!--                <vs-td :data="data[index].read">-->
<!--                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                    {{ data[index].read }}-->
<!--                  </vs-col>-->
<!--                </vs-td>-->


                <vs-td :data="data[index].id">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    <vs-button color="primary" icon="edit" size="small" @click.stop="editNotification(data[index])">
                      Uredi
                    </vs-button>

                    <vs-button class="ml-2" color="success" icon="send" size="small"
                               @click.stop="showSendNotification(data[index])">
                      Pošlji
                    </vs-button>
                  </vs-col>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
        <vs-tab label="Poslana obvestila" icon="done_all" @click="">
          <vs-table :data="notificationsData" @selected="showNotificationDetails">
            <template slot="header">
              <vs-navbar class="nabarx">
                <div slot="title">
                  <vs-navbar-title class="py-4">
                    Poslana obvestila
                  </vs-navbar-title>
                </div>
              </vs-navbar>
            </template>
            <template slot="thead">
              <vs-th>
                Datum
              </vs-th>
              <vs-th>
                Naslov
              </vs-th>
              <vs-th>
                Dogodek
              </vs-th>
              <vs-th>
                Naslovnik
              </vs-th>
              <vs-th>
                Prejeto
              </vs-th>
              <vs-th>
                Prebrano
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">
                <vs-td :data="data[index].date">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ moment(data[index].date).format('DD. MM. YYYY') }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].title">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].title }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].eventDetails">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].eventDetails ? JSON.parse(data[index].eventDetails)[0].eventTitle : '/' }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].customer">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].customer.firstName + ' ' + data[index].customer.lastName }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].received">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].received }}
                  </vs-col>
                </vs-td>

                <vs-td :data="data[index].read">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    {{ data[index].read }}
                  </vs-col>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
      </vs-tabs>
    </div>

    <vs-popup :title="'Pošiljanje obvestila'"
              :active.sync="showSendModal">
      <div v-if="selectedNotificationTemplate && customersData">
        <div class="grid grid-cols-1 mt-5">
          <div>
            Vsebina sporočila, ki ga želiš poslati:<br/>
            <h5><b>{{ selectedNotificationTemplate ? selectedNotificationTemplate.title : '' }}</b></h5>
            <b>{{ selectedNotificationTemplate ? selectedNotificationTemplate.body : '' }}</b>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div class="vx-col w-full">
            <label class="vs-input--label">
              Naslovnik/-i
            </label>
            <label class="ml-3 text-primary" v-if="selectedNotificationReceivers.length < customersData.length" @click="selectAllCustomers"><b>Izberi vse</b></label>
            <label class="ml-3 text-primary" v-if="selectedNotificationReceivers.length > 0" @click="() => { selectedNotificationReceivers = [];}"><b>Odstrani izbrane</b></label>



            <v-select :filter="fuseSearch" :options="customersData" class="" v-model="selectedNotificationReceivers"
                      :placeholder="'poišči naslovnike'"
                      :getOptionLabel="option => option.first_name + '' + option.last_name"
                      :reduce="item => item.id"
                      multiple>
              <template v-slot:option="option">
                <vs-list-item
                  :title="option.first_name + ' ' + option.last_name"
                  :subtitle="option.addresses.quickSearch">
                  <template slot="avatar">
                    <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam" :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"  />
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:selected-option="option">
                <vs-list-item
                  class="d-block"
                  :title="option.first_name + ' ' + option.last_name"
                  :subtitle="option.addresses.quickSearch">
                  <template slot="avatar">
                    <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam" :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"  />
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
              </template>
            </v-select>

<!--            <div class="flex mt-1" v-if="$v.customer.$dirty">-->
<!--              <div class="w-full">-->
<!--                <vs-alert :active="!$v.customer.required" :color="'danger'">-->
<!--                  Polje "{{!customer || customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica'}}" ne sme biti prazno.-->
<!--                </vs-alert>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </div>
        <div class="grid grid-cols-1 mt-20">
          <div>
            <vs-button size="medium" color="success" icon="check" @click="sendNotification()">
              <span>Pošlji</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!--    <div id="parentx">-->
    <!--      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer-->
    <!--                  v-model="sidebarActive">-->

    <!--        <Notification v-if="notificationId && sidebarActive" :notificationId="notificationId" />-->
    <!--      </vs-sidebar>-->
    <!--    </div>-->

  </div>
</template>

<script>
import Vue from 'vue';
import Notification from "../../components/Sopotniki/notifications/Notification";
import Fuse from "fuse.js";
import vSelect from "vue-select";
import sir from '../../assets/images/portrait/sir.svg';
import madam from '../../assets/images/portrait/madam.svg';

export default {
  name: 'units',

  components: {
    Notification,
    'v-select': vSelect,
  },

  data() {
    return {
      notificationsData: null,
      notificationsTemplateData: null,
      notificationId: null,
      sidebarActive: false,
      showSendModal: false,
      selectedNotificationTemplate: null,
      selectedNotificationReceivers: [],
      customersData: null,
      sir: sir,
      madam: madam,
    }
  },

  computed: {},

  mounted() {
    const _this = this;
    _this.getNotifications();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.notificationId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getNotifications() {
      const _this = this;

      _this.$vs.loading();


      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'notifications')
        .then((res) => {

          _this.notificationsData = _.filter(res.data.data, (val) => {
            return val.customer != null;
          });
          _this.notificationsTemplateData = _.filter(res.data.data, {customer: null});
          console.log("TOLE: ", res.data.data);


          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    showNotificationDetails(tr) {
      const _this = this;

      console.log("HERE!", tr);

      _this.notificationId = parseInt(tr.id);
      _this.sidebarActive = true;
    },

    editNotification(notification) {
      this.$router.push({name: 'NotificationEdit', params: {notificationId: notification.id}});
    },

    async showSendNotification(notification) {
      this.$vs.loading();
      await this.getCustomers();
      this.selectedNotificationTemplate = notification;
      this.showSendModal = true;
      this.$vs.loading.close();
    },

    async getCustomers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
        .then((res) => {
          _this.customersData = _.filter(res.data.data, (val) => { return (val.userId != null && val.active) });
          console.log("_this.customersData: ", _this.customersData);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    selectAllCustomers() {
      this.selectedNotificationReceivers = _.map(this.customersData, (val) => val.id);
    },

    sendNotification() {
      const _this = this;

      let data = {
        customerIds: this.selectedNotificationReceivers
      }

      _this.$vs.loading();
      Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'notifications/send/' + _this.selectedNotificationTemplate.id, data)
        .then((res) => {
          _this.$vs.notify({color:'success',title:'Obvestilo uspešno dostavljeno!',text:'', position: 'top-right'});
          _this.showSendModal = false;
          _this.$vs.loading.close();
          _this.getNotifications();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    addNotification() {
      this.$router.push({name: 'NotificationEdit'})
    },

    getUpnUrl(unit) {
      return require('@/assets/images/content/upn/upn_' + unit + '.pdf');
    },

    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["first_name", "last_name", "addresses.quick_search"],
        minMatchCharLength: 3,
        shouldSort: true
      });

      return search.length
        ? fuse.search(search)
          .map(({item}) => (item))
        : results.list;
    },

  }
}
</script>

<style>
  .vs-popup--content {
    overflow: visible !important;
  }
  .vs__selected-options {
    max-height: 400px;
    overflow-y: auto;
  }
</style>

